<template>
  <b-container fluid>
    <h3 class="my-4 text-capitalize">{{ $t('settings.settingsHeading') }}</h3>
    <h5 class="my-2">{{ $t('settings.project-managers') }}</h5>
    <b-table
      stripped
      hover
      :fields="projectManagersTableFields"
      :items="projectManagers"
    >
      <template #cell(name)="{ item }">
        {{ item.first_name }} {{ item.last_name }}
      </template>
      <template #cell(is_default)="{ item }">
        <b-form-radio
          v-model="defaultPM"
          @change="setDefaultProjectManager(item.sfid)"
          :value="item.sfid"
        />
      </template>
      <template #cell(action)="{ item }">
        <b-button variant="danger" @click="removeProjectManager(item.sfid)">
          Remove
        </b-button>
      </template>
    </b-table>
    <b-row class="my-4">
      <b-col lg="8">
        <template v-if="contactProfiles.includes('admin')">
          <h5 class="my-2">{{ $t('settings.add_project_manager') }}</h5>
          <b-row class="my-4">
            <b-col lg="4">
              <multiselect
                class="mx2"
                v-model="projectManager"
                label="name"
                track-by="sfid"
                :options="contactsList"
                :allow-empty="false"
              ></multiselect>
            </b-col>
            <b-col lg="4">
              <b-btn @click="setProjectManagerAndRefetch">
                {{ $t('settings.make-project-manager') }}
              </b-btn>
            </b-col>
          </b-row>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { ContactProfileEnum } from '@gid/models';

export default {
  async created() {
    if (this.user?.account.id != '0011n00001kGSc2AAG') {
      this.$router.push('/jobs');
    } else {
      await this.fetchContacts();
      await this.fetchProjectManagers();
    }
  },
  data() {
    return {
      projectManagersTableFields: [
        { key: 'name', label: this.$t('projects.project-manager.name') },
        {
          key: 'is_default',
          label: this.$t('projects.project-manager.default'),
        },
        { key: 'action', label: this.$t('projects.project-manager.action') },
      ],
      contactsList: [],
      projectManagersSelect: [],
      projectManagers: [],
      projectManager: '',
      defaultPM: this.projectManagers
        ? this.projectManagers.find((pm) => pm.is_default_project_manager).sfid
        : '',
      notificationsEnabled: false,
    };
  },
  computed: {
    ...mapGetters(['contactProfiles', 'user']),
  },
  watch: {
    projectManagers(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.projectManagers = newVal;
      }
    },
  },
  methods: {
    async fetchContacts() {
      const response = await axios.get(`/api/brand/account/contacts`);

      this.contactsList = response.data.map((entry) => ({
        sfid: entry.sfid,
        name: `${entry.first_name} ${entry.last_name}`,
      }));
    },
    async fetchProjectManagers() {
      const response = await axios.get(`/api/brand/project-managers`);
      this.projectManagersSelect = response.data.map((entry) => ({
        sfid: entry.sfid,
        name: `${entry.first_name} ${entry.last_name}`,
      }));
      this.projectManagers = response.data;
      this.defaultPM = this.projectManagers.find(
        (pm) => pm.is_default_project_manager,
      ).sfid;
    },
    async setProjectManager() {
      try {
        await axios.put(
          `/api/brand/account/contact/${this.projectManager.sfid}`,
          {
            contact_profile: [ContactProfileEnum.PROJECT_MANAGER_GENERIC],
          },
        );
        this.projectManager = '';
      } catch (e) {
        throw new Error(e);
      }
    },
    async setDefaultProjectManager(id) {
      try {
        await axios.put(`/api/brand/account/contact/${id}`, {
          contact_profile: [ContactProfileEnum.PROJECT_MANAGER_DEFAULT],
        });
        await this.fetchProjectManagers();
      } catch (e) {
        throw new Error(e);
      }
    },
    async setProjectManagerAndRefetch() {
      await this.setProjectManager();
      await this.fetchProjectManagers();
    },
    async removeProjectManager(pmId) {
      try {
        await axios.put(
          `/api/brand/account/contact/${pmId}/revoke/project-manager`,
        );
        await this.fetchProjectManagers();
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};
</script>
